@mixin link($light, $dark) {
  a,
  a::before,
  a::after {
    text-decoration: none;

    @if $light {
      color: $global-link-color;
    }

    @else {
      color: $single-link-color;
    }

    [data-theme='dark'] & {
      @if $dark {
        color: $global-link-color-dark;
      }

      @else {
        color: $single-link-color-dark;
      }
    }
  }

  a:active,
  a:hover {
    @if $light {
      color: $global-link-hover-color;
    }

    @else {
      color: $single-link-hover-color;
    }

    [data-theme='dark'] & {
      @if $dark {
        color: $global-link-hover-color-dark;
      }

      @else {
        color: $single-link-hover-color-dark;
      }
    }
  }
}
