.fixit-decryptor-container {
  font-family: $global-font-family;
  text-align: center;
  margin-top: 3rem;

  .fixit-encryptor-shortcode & {
    margin-top: 1rem;
  }

  #fixit-decryptor-input,
  .fixit-decryptor-input,
  .fixit-encryptor-btn {
    display: inline-block;
    box-sizing: border-box;
    outline: none;
    color: $global-font-color;
    border: 1px solid darken($global-border-color, 15%);
    border-radius: $global-border-radius;
    @include transition(all 0.1s ease-out);
    box-shadow: 0px 0px 5px 0px darken($global-border-color, 15%);

    &:active,
    &:focus,
    &:hover {
      color: darken($global-link-hover-color, 6%);
      border-color: $global-link-hover-color;
      box-shadow: 0px 0px 5px 0px lighten($global-link-hover-color, 20%);

      [data-theme='dark'] & {
        color: lighten($global-link-hover-color-dark, 6%);
        border-color: $global-link-hover-color-dark;
        box-shadow: 0px 0px 5px 0px darken($global-link-hover-color-dark, 20%);
      }
    }

    [data-theme='dark'] & {
      color: $global-font-color-dark;
      border-color: lighten($global-border-color-dark, 15%);
      box-shadow: 0px 0px 5px 0px lighten($global-border-color-dark, 15%);
    }
  }

  #fixit-decryptor-input,
  .fixit-decryptor-input {
    width: clamp(50%, 400px, 100%);
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: $global-background-color;

    [data-theme='dark'] & {
      background-color: $global-background-color-dark;
    }
  }

  .fixit-encryptor-btn {
    cursor: pointer;
    @include transition(all 0.1s ease-out);
    padding: 0.6rem 1rem;

    background-color: $header-background-color;

    [data-theme='dark'] & {
      background-color: $header-background-color-dark;
    }
  }
}
