@media only screen and (min-width: 1441px) {
  %page-style {
    width: 60%;

    [data-page-style='wide'] & {
      width: 64%;
    }

    [data-page-style='narrow'] & {
      max-width: 800px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  %page-style {
    width: 56%;

    [data-page-style='wide'] & {
      width: 60%;
    }
  }
}

@media only screen and (max-width: 1200px) {
  %page-style {
    width: 52%;

    [data-page-style='wide'] & {
      width: 56%;
    }
  }

  #header-desktop .header-wrapper {
    padding-right: 1rem;
  }

  .search-dropdown.desktop {
    right: 1rem;
  }
}

@media only screen and (max-width: 960px) {
  %page-style {
    width: 80% !important;
  }
  
  aside {
    display: none !important;
  }

  #toc-static {
    display: block;
  }

  #header-desktop .header-wrapper {
    padding-left: 1rem;
  }
}


@media only screen and (min-width: 681px) {
  .breadcrumb-container.sticky {
    body:not([data-header-desktop='sticky']) & {
      top: 0;
    }
    // adjust the scroll margin top of the content anchors on the page
    body:not([data-header-desktop='auto']) &+.container .content [id] {
      scroll-margin-top: calc(var(--#{$prefix}scroll-mt) + var(--#{$prefix}breadcrumb-offset));
    }
  }

  [data-header-desktop='normal'] .page .content [id] {
    --#{$prefix}scroll-mt: #{$global-scroll-margin-top};
  }
}

@media only screen and (max-width: 680px) {
  #header-desktop {
    display: none;
  }

  #header-mobile,
  .d-none-desktop {
    display: block;
  }

  .d-none-mobile {
    display: none !important;
  }

  .breadcrumb-container.sticky {
    body:not([data-header-mobile='sticky']) & {
      top: 0;
    }
    // adjust the scroll margin top of the content anchors on the page
    body:not([data-header-mobile='auto']) &+.container .content [id] {
      scroll-margin-top: calc(var(--#{$prefix}scroll-mt) + var(--#{$prefix}breadcrumb-offset));
    }
  }

  [data-header-mobile='normal'] .page .content [id] {
    --#{$prefix}scroll-mt: #{$global-scroll-margin-top};
  }
  
  %page-style {
    width: 100% !important;
  }

  .page {
    .categories-card {
      .card-item {
        width: 100%;
      }
    }
  }

  footer {
    .footer-container {
      font-size: 0.618rem;
    }
  }

  .pagination .page-item {
    margin: 0 5px;
  }
}

@media only print {
  @page {
    size: A4 portrait;
    margin: 1.27cm;
  }

  .page {
    width: 100% !important;
    padding-top: 0 !important;

    &.single {
      .single-title,
      .single-subtitle,
      .post-meta {
        text-align: center;
      }
    }

    h1:not(.single-title),
    .page-break-before {
      page-break-before: always;
    }

    .page-break-after {
      page-break-after: always;
    }
  }

  .print-d-none {
    display: none !important;
  }
}
